import React from 'react';
import type { PlanT } from '@mentimeter/plans-content';
import { AnimatedTextSkeleton } from '@mentimeter/plans-content';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';

export function MainPrice({ plan }: { plan: PlanT }) {
  return (
    <Box flex="1 1 auto" flexDirection="row" width="100%" alignItems="flex-end">
      {plan.isPriceLoading ? (
        <AnimatedTextSkeleton />
      ) : (
        <Text
          color="text"
          fontSize={7}
          lineHeight="none"
          mt="space1"
          data-testid={`${plan.id}-plan-price`}
        >
          {plan.price}
          {Boolean(plan.pricePostfix) && (
            <Box
              display="inline-block"
              extend={() => ({ verticalAlign: 'top' })}
            >
              <Text
                fontWeight="bold"
                color="text"
                extend={() => ({ display: 'block' })}
              >
                {plan.pricePostfix}
              </Text>
            </Box>
          )}
        </Text>
      )}
    </Box>
  );
}
