import type { ReactNode } from 'react';
import React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';
import { usePlansPageMode } from '../../../hooks/usePlansPageMode';

interface PlanCellProps {
  children?: ReactNode;
  highlight?: boolean;
  showTopBorder?: boolean;
  showBottomBorder?: boolean;
}

export const PlanCell = ({
  children,
  highlight = false,
  showTopBorder = false,
  showBottomBorder = false,
  ...rest
}: PlanCellProps & BoxT) => {
  const { isCompactMode } = usePlansPageMode();
  const borderWidth = highlight ? '1.5px' : '1px';

  return (
    <Box
      px="space6"
      pt={showTopBorder ? 'space8' : 'space4'}
      pb={showBottomBorder ? 'space8' : 'space4'}
      mb={showBottomBorder && isCompactMode ? 'space8' : 'space0'}
      bg={highlight ? 'brandWeakest' : 'bg'}
      borderColor={highlight ? 'brand' : 'border'}
      borderStyle="solid"
      borderLeftWidth={borderWidth}
      borderRightWidth={borderWidth}
      borderTopWidth={showTopBorder ? borderWidth : 0}
      borderBottomWidth={showBottomBorder ? borderWidth : 0}
      borderTopLeftRadius={showTopBorder ? '3xl' : undefined}
      borderTopRightRadius={showTopBorder ? '3xl' : undefined}
      borderBottomLeftRadius={showBottomBorder ? '3xl' : undefined}
      borderBottomRightRadius={showBottomBorder ? '3xl' : undefined}
      alignSelf="stretch"
      extend={() => ({
        ':not(:last-child)': {
          marginBottom: showBottomBorder && isCompactMode ? 4 : 0,
        },
      })}
      {...rest}
    >
      {children}
    </Box>
  );
};
