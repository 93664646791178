import React from 'react';
import type { UspT } from '@mentimeter/plans-content';
import { Usps } from '@mentimeter/plans-ui';
import { Text } from '@mentimeter/ragnar-ui/text';

export function UniqueSellingPoints({
  uspHeading,
  usps,
}: { uspHeading: string | undefined; usps: Array<UspT | false> }) {
  return (
    <React.Fragment>
      <Text color="text" fontWeight="semiBold" mb="space2">
        {/* eslint-disable-next-line menti-react-jsx/no-possibly-undefined-in-jsx */}
        {uspHeading}
      </Text>
      <Usps usps={usps} />
    </React.Fragment>
  );
}
