import React from 'react';
import type { PlanT } from '@mentimeter/plans-content';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Badge } from '@mentimeter/ragnar-ui/badge';

export function DiscountedPrice({
  plan,
  discountPrice,
}: {
  plan: PlanT;
  discountPrice: string;
}) {
  return (
    <Box flexDirection="row" alignItems="center">
      <Text
        color="textWeaker"
        lineHeight="100"
        extend={() => ({ textDecoration: 'line-through' })}
      >
        {discountPrice}
      </Text>
      <Box flexDirection="row" alignItems="center">
        <Badge
          bg="positiveWeak"
          px="space2"
          ml="space2"
          type="text"
          color="onPositiveWeak"
          text={plan.discount!.save}
          compact
        />
      </Box>
    </Box>
  );
}
