import React from 'react';
import type { ButtonT } from '@mentimeter/ragnar-ui/button';
import { Button } from '../../actions';

interface BuyPlanButtonProps {
  highlight?: boolean | undefined;
  compact?: boolean;
}

export const BuyPlanButton = React.forwardRef<
  HTMLButtonElement,
  BuyPlanButtonProps & ButtonT
>(({ children, highlight = false, compact = false, ...rest }, _ref) => {
  return (
    <Button
      width="100%"
      variant={highlight ? 'primary' : 'outline'}
      extend={({ theme }) => ({
        height: '48px',
        fontSize: `${compact ? theme.fontSizes[1] : theme.fontSizes[2]}px`,
      })}
      {...rest}
    >
      {children}
    </Button>
  );
});
