import { useSubscriptions } from '@mentimeter/billing';
import {
  CheckoutModal,
  useCheckoutModals,
  type HandlePlanClickT,
} from '@mentimeter/checkout-modal';
import { PlanCategory } from '@mentimeter/http-clients';
import { useRouter, useSearchParams } from '@mentimeter/next-navigation';
import { useUser, userPolicies } from '@mentimeter/user';

interface CheckoutModalWrapperPropsT {
  location?: string;
  children: ({
    handlePlanClick,
  }: {
    handlePlanClick: HandlePlanClickT;
  }) => React.ReactNode;
}

export function CheckoutModalsWrapper({
  location,
  children,
}: CheckoutModalWrapperPropsT) {
  const { push } = useRouter();
  const { user } = useUser();
  const { subscriptions } = useSubscriptions(!user);
  const { isEnterpriseUser } = userPolicies(subscriptions);
  const searchParams = useSearchParams();

  const isCheckoutModalEnabled = !isEnterpriseUser && !user?.isFreeUser;
  const preSelectedPlan = subscriptions?.current_subscription?.plan.category;
  const hasPreSelectedPlan =
    preSelectedPlan !== undefined &&
    (preSelectedPlan === PlanCategory.BASIC ||
      preSelectedPlan === PlanCategory.PRO);
  // this location prop is used so we only render the modal from the features page since this modal is imported on other places on the plans page as well
  const isLicenseModalInitiallyOpen =
    searchParams?.has('open-licenses-modal') &&
    location === 'features' &&
    hasPreSelectedPlan;

  const {
    handlePlanClick,
    licensesModalOpen,
    planModalOpen,
    billingCycleModalOpen,
    selectedPlan,
    setLicensesModalOpen,
    setPlanModalOpen,
    setBillingCycleModalOpen,
  } = useCheckoutModals({
    enabled: !user ? false : isCheckoutModalEnabled,
    isLicenseModalInitiallyOpen,
    currentPlan: preSelectedPlan,
    onDisabledCheckoutModalClick: (href) => {
      push(href ?? '/plans');
    },
  });
  return (
    <>
      {children({ handlePlanClick })}
      <CheckoutModal
        open={planModalOpen}
        onOpenChange={setPlanModalOpen}
        plan={selectedPlan}
        type="plan"
        isCheckoutModalEnabled={isCheckoutModalEnabled}
      />
      <CheckoutModal
        open={licensesModalOpen}
        onOpenChange={setLicensesModalOpen}
        plan={isLicenseModalInitiallyOpen ? preSelectedPlan : selectedPlan}
        type="licenses"
        isCheckoutModalEnabled={isCheckoutModalEnabled}
      />
      <CheckoutModal
        open={billingCycleModalOpen}
        onOpenChange={setBillingCycleModalOpen}
        plan={selectedPlan}
        type="billing-cycle"
        isCheckoutModalEnabled={isCheckoutModalEnabled}
      />
    </>
  );
}
