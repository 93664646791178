import React from 'react';
import type { PlanT, MetaT } from '@mentimeter/plans-content';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Badge } from '@mentimeter/ragnar-ui/badge';

export function AdditionalPriceInfo({
  plan,
  showYearlyDiscount,
}: {
  plan: PlanT;
  showYearlyDiscount: (item: MetaT) => boolean;
}) {
  return (
    <Box>
      {plan.newPriceMeta?.map((item) => (
        <Box key={item.text} flexDirection="row" alignItems="center">
          <Text key={item.text} color="textWeaker" fontSize="87.5">
            {item.text}
            {showYearlyDiscount(item) && (
              <Box display="inline-block">
                <Badge
                  bg="positiveWeak"
                  px="space2"
                  mt="space0"
                  ml="space2"
                  type="text"
                  color="onPositiveWeak"
                  text={item.yearlyDiscountText}
                  compact
                />
              </Box>
            )}
          </Text>
        </Box>
      ))}
    </Box>
  );
}
